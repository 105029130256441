<template>
  <contacts-module-component />
</template>

<script>
import ContactsModuleComponent from "@/components/admin/ContactsModuleComponent";

export default {
  name: "ContactsModules",
  title: "Gestión de Contactos | Baja California Health Tourism",
  components: { ContactsModuleComponent },
};
</script>

<style scoped></style>
