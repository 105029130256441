<template>
  <v-container fill-height fluid text-xs-center>
    <v-card class="fill-height container--fluid" width="100vw" flat outlined>
      <v-data-table
        :headers="headers"
        :items="contacts"
        :items-per-page="-1"
        :search="searchContact"
        :loading="loadingData"
        :expanded="expanded"
        single-expand
        show-expand
        item-key="id"
        sort-by="id"
        disable-sort
        no-data-text="No hay datos para mostrar..."
        loading-text="Cargando los datos, por favor espere!"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: false,
          disableItemsPerPage: false,
          itemsPerPageAllText: 'Todas',
          itemsPerPageText: 'Filas por página:',
        }"
      >
        <template v-slot:top>
          <v-toolbar flat class="align-center">
            <v-toolbar-title>Gestión de Contactos</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-responsive
              max-width="300"
              height="45"
              class="hidden-sm-and-down"
            >
              <v-text-field
                id="findtext-contact"
                label="Buscar.."
                name="findcontact"
                outlined
                dense
                single-line
                clearable
                background-color="white"
                hide-details
                v-model="searchContact"
                class="white--text mt-0 pt-0 mr-2"
                prepend-inner-icon="mdi-magnify"
              ></v-text-field>
            </v-responsive>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h6 headline"
                  >Esta seguro que desea eliminar este elemento?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancelar</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >Proceder</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogAttend" max-width="500px">
              <v-card>
                <v-card-title class="text-h6 headline">
                  Desea atender y procesar este contacto?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeAttend">
                    Cancelar
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="attendItemConfirm">
                    Proceder
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.attended="{ item }">
          <v-simple-checkbox v-model="item.attended" disabled>
          </v-simple-checkbox>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="mr-2"
                @click="doAttendItem(item)"
              >
                mdi-account-check
              </v-icon>
            </template>
            <span>Contacto Atendido?</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="mr-2"
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <span class="text-h5">No hay registros en el listado!</span>
          <br />
          <v-btn color="primary" @click="getContacts"> Recargar </v-btn>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-container fluid class="fill-height flex">
              <v-row dense justify="start" align="start" class="ma-0">
                <v-col cols="12" md="6">
                  <v-card flat class="pt-0">
                    <v-card-title>
                      <span>Nombres y Apellido: </span>
                    </v-card-title>
                    <v-card-subtitle class="text-subtitle-1">
                      {{ item.name }}
                    </v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="12" md="6">
                  <v-card flat class="pt-0">
                    <v-card-title>
                      <span>Correo Electrónico: </span>
                    </v-card-title>
                    <v-card-subtitle class="text-subtitle-1">
                      {{ item.email }}
                    </v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="12" md="6">
                  <v-card flat class="pt-0">
                    <v-card-title>
                      <span>Teléfonos: </span>
                    </v-card-title>
                    <v-card-subtitle class="text-subtitle-1">
                      {{ item.phone | formatPhone("MX") }}
                    </v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="12" md="6">
                  <v-card flat class="pt-0">
                    <v-card-title>
                      <span>Servicio solicitado: </span>
                    </v-card-title>
                    <v-card-subtitle class="text-subtitle-1">
                      {{ item.service }}
                    </v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="12" md="6">
                  <v-card flat class="pt-0">
                    <v-card-title>
                      <span>Contacto Atendido?: </span>
                    </v-card-title>
                    <v-card-subtitle class="text-subtitle-1">
                      <v-simple-checkbox v-model="item.attended" disabled>
                      </v-simple-checkbox>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </td>
        </template>
      </v-data-table>
      <v-snackbar v-model="snackBar" :timeout="2000">
        {{ snackText }}
        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" icon @click="snackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-container>
</template>

<script>
import contactsService from "@/providers/ContactsService";

export default {
  name: "ContactsModuleComponent",
  data: () => ({
    snackBar: false,
    snackText: "",
    searchContact: "",
    loadingData: false,
    dialog: false,
    dialogDelete: false,
    dialogAttend: false,
    contacts: [],
    expanded: [],
    headers: [
      { text: "", value: "data-table-expand", width: 5 },
      { text: "Id", value: "id", align: " d-none" }, // para ocultar la columna Id.
      {
        text: "Nombre",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "E-mail", value: "email" },
      { text: "Atendido", value: "attended", align: "center", width: 100 },
      {
        text: "Acciones",
        value: "actions",
        width: 20,
        align: "center",
        sortable: false,
      },
    ],
    editedIndex: -1,
    editedItem: {
      id: 0,
      name: "",
      email: "",
      service: "",
      phone: "",
      comment: "",
      validated: false,
      attended: false,
    },
    defaultItem: {
      id: 0,
      name: "",
      email: "",
      service: "",
      phone: "",
      comment: "",
      validated: false,
      attended: false,
    },
    nameRules: [
      (v) => !!v || "El Nombre es obligatorio!",
      // v => (v && v.length >= 10) || 'El Nombre debe tener mas de un caracter!',
    ],
  }),
  filters: {
    formatPhone(phn, country) {
      // console.log('phn:', phn);
      if (phn == "undefined" || phn == null || phn === "") {
        return country;
      }
      switch (country) {
        case "US":
          return (
            "USA +1 (" +
            phn.substring(0, 3) +
            ") " +
            phn.substring(3, 6) +
            "-" +
            phn.substring(6)
          );
        case "MX":
          return (
            "MEX +52 (" +
            phn.substring(0, 3) +
            ") " +
            phn.substring(3, 6) +
            "-" +
            phn.substring(6)
          );
        default:
          return country;
      }
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo contacto" : "Editar contacto";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogAttend(val) {
      val || this.closeAttend();
    },
  },
  methods: {
    getContacts() {
      // carga los datos desde el proveedor de datos.
      this.loadingData = true;
      contactsService.getAllRecords().then((record) => {
        this.contacts = record.value;
        // console.log("Contactos: ", this.contacts);
        this.loadingData = false;
      });
    },
    editItem(item) {
      this.editedIndex = this.contacts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.contacts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // console.log('Item to delete: %s %s ', this.editedItem.id, this.editedItem);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.contacts.splice(this.editedIndex, 1);
      contactsService.deleteRecord(this.editedItem.id);
      // console.log('Item deleted:', this.editedItem);
      this.closeDelete();
      this.snackText = "Registro eliminado con éxito.";
      this.snackBar = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    doAttendItem(item) {
      // console.log('Logica para atender al contacto...', item);
      this.editedItem = Object.assign({}, item);
      this.dialogAttend = true;
    },
    attendItemConfirm() {
      this.editedItem.attended = true;
      contactsService.attendContact(this.editedItem).then((result) => {
        this.closeAttend();
        if (result.success) {
          this.snackText = "Contacto procesado y atendido!";
        } else {
          this.snackText = "Un error impidió procesar este contacto!";
        }
        this.snackBar = true;
        this.getContacts();
      });
    },
    closeAttend() {
      this.dialogAttend = false;
    },
  },
  mounted() {
    this.contacts = [];
    this.getContacts();
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: none !important;
}
</style>
