import { render, staticRenderFns } from "./ContactsModules.vue?vue&type=template&id=19980dd4&scoped=true"
import script from "./ContactsModules.vue?vue&type=script&lang=js"
export * from "./ContactsModules.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19980dd4",
  null
  
)

export default component.exports